import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import _ from "lodash"
import { useSelector } from "react-redux"
import {
  FaMapMarkedAlt,
  FaSave,
  GiAbstract096,
  GiBandaged,
  GiBleedingHeart,
  GiSettingsKnobs,
  GiTreasureMap,
  MdDataUsage,
} from "react-icons/all"

import { checkFeatureExists, fixIsoCode, getIdByFeatureName } from "../utils"
import { useFeature, useLanguage, useSubmit, useWindowSize } from "../hooks"
import {
  AdaptiveContainer,
  Anthropogenic,
  AsteroidFeature,
  Centered,
  ColumnContainer,
  DescriptionFeature,
  DescriptionInfo,
  Footer,
  Loader,
  MenuButton,
  MenuItem,
  PageContainer,
  Scroll,
  SettingsMenuItem,
  Title,
  TopBar,
  TopMenu,
  VirusConfig,
} from "../components"
import {
  setCountry,
  setDisasterName,
  setDuration,
  setFeature,
  setPower,
  setRegion,
  setScale,
} from "../data/actions"
import { iconProps } from "../constants"
import { withTranslationHOC } from "../i18n/with-translation"

function Settings() {
  const { t, lang } = useLanguage()
  const { isMobile } = useWindowSize()
  const [mapData, setMapData] = useState([])
  const {
    isLoaded,
    isDisastersLoaded,
    handleClickWorldMap,
    receivedData,
    handleDispatch,
    handleSubmit,
    handleLoad,
    selectAllSymptoms,
    selectSymptoms,
    symptoms,
  } = useSubmit()

  const { checkFeatureFilled } = useFeature()

  const {
    name,
    feature,
    power,
    duration,
    scale,
    country,
    region,
  } = useSelector(({ settings }) => settings)

  useEffect(() => {
    if (!country) {
      return
    }
    const { population, code } = country
    const data = {
      value: population,
      text: "",
      country: fixIsoCode(code),
    }
    setMapData([data])
  }, [country])

  return (
    <PageContainer>
      <Helmet>
        <title>{t("menu.settings")}</title>
      </Helmet>

      {!isLoaded && <TopBar />}

      <Scroll>
        <ColumnContainer w={isMobile ? 300 : 400}>
          <TopMenu fixed>
            <Title fontSize={24}>{t("settings.title")}</Title>
          </TopMenu>

          {isDisastersLoaded && (
            <AdaptiveContainer
              css={`
                margin-top: 30px;
                z-index: 2;
              `}
            >
              <SettingsMenuItem
                title={t(`settings.disaster.name.value`)}
                description={
                  (name && name.name[lang]) ||
                  t(`settings.disaster.name.description`)
                }
                notFilled={!name || !name.name[lang]}
                data={receivedData.disasterType}
                id="idDisaster"
                onLoad={_.noop}
                onClick={handleDispatch(setDisasterName)}
                icon={<GiBleedingHeart {...iconProps} />}
              />

              {name && checkFeatureExists(name) && (
                <SettingsMenuItem
                  title={t(`settings.disaster.${name.name.en}.value`)}
                  description={
                    (feature && feature.name[lang]) ||
                    t(`settings.disaster.${name.name.en}.description`)
                  }
                  notFilled={!feature || !feature.name[lang]}
                  data={receivedData.features}
                  id={getIdByFeatureName(name.name.en)}
                  onLoad={handleLoad("feature")}
                  onClick={handleDispatch(setFeature)}
                  noDescription
                  icon={<MdDataUsage {...iconProps} />}
                />
              )}

              <AsteroidFeature
                receivedData={receivedData}
                handlers={{ handleLoad, handleDispatch }}
              />

              <Anthropogenic
                receivedData={receivedData}
                handlers={{ handleLoad, handleDispatch }}
              />

              <VirusConfig />

              {name && (
                <SettingsMenuItem
                  title={t(`settings.disaster.power.value`)}
                  description={
                    (power && power.name[lang]) ||
                    t(`settings.disaster.power.description`)
                  }
                  notFilled={!power || !power.name[lang]}
                  data={receivedData.levels}
                  id="idLvl"
                  onLoad={handleLoad("lvl")}
                  onClick={handleDispatch(setPower)}
                  icon={<GiSettingsKnobs {...iconProps} />}
                />
              )}

              {power && (
                <SettingsMenuItem
                  title={t(`settings.disaster.duration.value`)}
                  description={
                    (duration && duration.name[lang]) ||
                    t(`settings.disaster.duration.description`)
                  }
                  notFilled={!duration || !duration.name[lang]}
                  data={receivedData.durations}
                  id="idDuration"
                  onLoad={handleLoad("duration")}
                  onClick={handleDispatch(setDuration)}
                  icon={<GiBandaged {...iconProps} />}
                />
              )}

              {duration && (
                <SettingsMenuItem
                  title={t(`settings.disaster.scale.value`)}
                  description={
                    (scale && scale.name[lang]) ||
                    t(`settings.disaster.scale.description`)
                  }
                  notFilled={!scale || !scale.name[lang]}
                  data={receivedData.scales}
                  id="idScale"
                  onLoad={handleLoad("scale")}
                  onClick={handleDispatch(setScale)}
                  icon={<GiAbstract096 {...iconProps} />}
                />
              )}

              {name && (
                <SettingsMenuItem
                  title={t(`settings.disaster.country.value`)}
                  description={
                    (country && country.name[lang]) ||
                    t(`settings.disaster.country.description`)
                  }
                  notFilled={!country || !country.name[lang]}
                  data={receivedData.countries}
                  id="idCountry"
                  onLoad={_.noop}
                  onClick={handleDispatch(setCountry)}
                  icon={<GiTreasureMap {...iconProps} />}
                />
              )}

              {country && !_.isEmpty(receivedData.regions) && (
                <SettingsMenuItem
                  title={t(`settings.disaster.region.value`)}
                  description={
                    (region && region.name[lang]) ||
                    t(`settings.disaster.region.description`)
                  }
                  notFilled={!region || !region.name[lang]}
                  data={receivedData.regions}
                  id="idRegion"
                  onLoad={_.noop}
                  onClick={handleDispatch(setRegion)}
                  icon={<FaMapMarkedAlt {...iconProps} />}
                />
              )}

              {name &&
                checkFeatureFilled() &&
                power &&
                duration &&
                scale &&
                country &&
                (_.isEmpty(receivedData.regions) || region) && (
                  <MenuButton onClick={handleSubmit}>
                    <MenuItem
                      title={t("settings.submit.title")}
                      description={t("settings.submit.description")}
                      icon={<FaSave {...iconProps} />}
                    />
                  </MenuButton>
                )}
            </AdaptiveContainer>
          )}
        </ColumnContainer>

        {isDisastersLoaded && name && (
          <>
            {feature && (
              <DescriptionFeature
                symptoms={symptoms}
                selectSymptoms={selectSymptoms}
                selectAllSymptoms={selectAllSymptoms}
              />
            )}
            <DescriptionInfo mapData={mapData} onClick={handleClickWorldMap} />
          </>
        )}

        {!isDisastersLoaded && (
          <Centered>
            <Loader />
          </Centered>
        )}
      </Scroll>

      <Footer status={isLoaded ? t("status.done") : t("status.refresh")} />
    </PageContainer>
  )
}

export default withTranslationHOC(Settings)
